

















import {Component, Vue} from 'vue-property-decorator';
import {metaData} from '@/metadata';
import {beginPoot, globalXOffset, initializeScene, parts, pootEind, tussenStuk1, tussenStuk3} from '@/scene';

@Component({
    components: {},
})
export default class App extends Vue {
    cameraPosition = '';
    part1Position = '';



    constructor() {
        super();
        initializeScene(this);
    }

    setScaleTussenStuk1(event: any) {
        const scale = 0.5 + 0.5 * event.target.valueAsNumber / 100;
        const delta = 29.843 - 29.843 * scale;
        tussenStuk1.forEach(part => {
            const x = part.x - globalXOffset + delta * 2;
            part.meshes?.forEach(mesh => {
                mesh.position.setX(x);
                const scaleX = ((part?.scale?.x ?? 0) - delta) / (part?.box?.x ?? 1);
                mesh.scale.setX(scaleX);
                mesh.updateMatrix();
            });
        });
        beginPoot.forEach(part => {
            const x = part.x - globalXOffset + delta * 2;
            part.meshes?.forEach(mesh => {
                mesh.position.setX(x);
                mesh.updateMatrix();
            });
        });
        console.log(event.target.valueAsNumber, scale);
    }

    setScaleTussenStuk3(event: any) {
        const scale = 0.5 + 0.5 * event.target.valueAsNumber / 100;
        const tussenStuk3SizeDelta = 59.874 - 59.874 * scale;
        tussenStuk3.forEach(part => {
            part.meshes?.forEach(mesh => {
                const scaleX = (part?.scale?.x ?? 0) / (part?.box?.x ?? 1) * scale;
                mesh.scale.setX(scaleX);
                mesh.updateMatrix();
            });
        });
        pootEind.forEach(part => {
            const x = part.x - globalXOffset - tussenStuk3SizeDelta * 2;
            part.meshes?.forEach(mesh => {
                mesh.position.setX(x);
                mesh.updateMatrix();
            });
        });
        console.log(event.target.valueAsNumber, scale);
    }

    movePart(axes: 'x' | 'y' | 'z', value: number) {
        const i = parts.length - 1;
        parts[i].position[axes] += value / 100;
        this.part1Position = JSON.stringify(parts[i].position);
    }

    public setPosition(obj: any) {
        this.cameraPosition = JSON.stringify(obj);
    }
}
