import {Vector3d} from "@/scene.entity";
import {Mesh} from "three";

export namespace SceneEngine {
    export function getBoundingBoxSize(mesh: Mesh): Vector3d | null {
        if (!mesh.geometry) return null;
        if (!mesh.geometry.boundingBox) mesh.geometry.computeBoundingBox();
        const {boundingBox} = mesh.geometry;
        if (!boundingBox) return null;
        return {
            x: parseFloat(Math.abs(boundingBox.max.x - boundingBox.min.x).toFixed(3)),
            y: parseFloat(Math.abs(boundingBox.max.y - boundingBox.min.y).toFixed(3)),
            z: parseFloat(Math.abs(boundingBox.max.z - boundingBox.min.z).toFixed(3)),
        };
    }
}
