import * as THREE from 'three';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {OrbitControls} from 'three/examples/jsm/controls/OrbitControls';
import App from '@/App.vue';
import {Box3, Object3D} from 'three';
import {SceneReaderEngine} from '@/engine/scene-reader.engine';

const scene = new THREE.Scene();
// const camera = new THREE.PerspectiveCamera( 75, window.innerWidth / window.innerHeight, 0.1, 1000 );
const camera = new THREE.PerspectiveCamera(45, window.innerWidth / window.innerHeight, 1, 1000);

const renderer = new THREE.WebGLRenderer();
renderer.setSize(window.innerWidth, window.innerHeight);
document.body.appendChild(renderer.domElement);

// const geometry = new THREE.BoxGeometry();
// const material = new THREE.MeshBasicMaterial( { color: 0x00ff00 } );
// const cube = new THREE.Mesh( geometry, material );
// scene.add( cube );


// load models
const loader = new GLTFLoader();

export let parts: Object3D[] = [];
const models: { [modelName: string]: GLTF } = {};

async function loadModel(location: string, modelName: string) {
    return new Promise((res, rej) => {
        loader.load(location, function (gltf) {
                models[modelName] = gltf;
                res();
            }, undefined, function (error) {
                console.error(error);
                rej();
            }
        );
    });
}

export interface Coordinates3D {x: number, y: number, z: number}
interface Part extends Coordinates3D{
    box?: Partial<Coordinates3D>;
    scale?: Partial<Coordinates3D>;
    piece_id: string;
    object_name: string;
    meshes?: THREE.Mesh[];
}

export const beginPoot: Part[] = [
    {x: 0, y: 0, z: 0, "piece_id": "", object_name: 'poot_begin'},
    {x: 269.2002 - 276.4043, y: 88 - 0, z: 92.69625 - 85.00003, "piece_id": "", object_name: 'blad_begin'},
];

export const tussenStuk1: Part[] = [
    {x: 269.2002 - 265.7002, y: 88 - 0, z: 92.69625 - 85.00003, box: {x: 5.423}, scale: {x: 29.843 + 1.75 + 2.867}, "piece_id": "Object071", object_name: 'blad_tussenstuk'},
    {x: 269.2002 - 265.7002, y: 88 - 72.45003, z: 0 - 0.00002, box: {x: 5}, scale: {x: 29.843}, "piece_id": "Object023_008", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 52.45002, z: 0 - 0.00002, box: {x: 5}, scale: {x: 29.843}, "piece_id": "Object023_009", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 3.2001, z: 0 - 0, box: {x: 5, y: 1.75}, scale: {x: 29.843, y: 3.5}, "piece_id": "Object023_013", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 52.45002, z: 0 - 56.49999, box: {x: 5}, scale: {x: 29.843}, "piece_id": "Object023_018", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 72.45003, z: 0 - 56.49999, box: {x: 5}, scale: {x: 29.843}, "piece_id": "Object023_017", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 72.45003, z: 0 - 85.00004, box: {x: 5}, scale: {x: 71.226}, "piece_id": "Object023_001", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 52.45002, z: 0 - 85.00004, box: {x: 5}, scale: {x: 71.226}, "piece_id": "Object023_002", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 32.44717, z: 0 - 85.00004, box: {x: 5}, scale: {x: 71.226}, "piece_id": "Object023_026", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.7002, y: 88 - 3.2001, z: 0 - 85.00004, box: {x: 5, y: 1.75}, scale: {x: 71.226, y: 3.5}, "piece_id": "Object023_005", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 265.70068, y: 88 - 28.94719, z: 0 - 81.90636 + 25.213 - 3.5, box: {x: 5}, scale: {x: 71.226}, "piece_id": "Object023_039", object_name: 'tussenstuk_plaat'},
];

export const poot1: Part[] = [
    {x: 269.2002 - 206.01367, y: 88 - 88, z: 0, "piece_id": "poot_02", object_name: 'poot_midden_klein'},
];

export const tussenStuk2: Part[] = [
    {x: 269.2002 - 202.51367, y: 88 - 72.45003, z: 0 - 0, box: {x: 5}, scale: {x: 39.632}, "piece_id": "Object023_010", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 202.51367, y: 88 - 3.2001, z: 0 - 0.00002, box: {x: 5, y: 1.75}, scale: {x: 39.632, y: 3.5}, "piece_id": "Object023_014", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 202.51367, y: 88 - 3.2001, z: 0 - 56.49999, box: {x: 5, y: 1.75}, scale: {x: 39.632, y: 3.5}, "piece_id": "Object023_022", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 202.51367 + 2.867 * 2, y: 88 - 0, z: 92.69625 - 85.00003, "piece_id": "Object074", object_name: 'blad_lavabo'},
    {x: 269.2002 - 202.51367 - 0.004 + 2.867 * 2 + 33.898 * 2, y: 88 - 0, z: 92.69625 - 85.00003,
        box: {x: 5.423}, scale: {x: 2.867 + 0.004}, "piece_id": "Object071", object_name: 'blad_tussenstuk'},
];

export const poot2: Part[] = [
    {x: 269.2002 - 123.24854, y: 88 - 88, z: 0, "piece_id": "poot_03", object_name: 'poot_midden'},
    {x: 269.2002 - 123.24854 - 0.004, y: 88 - 0, z: 92.69625 - 85.00003, box: {x: 5.423}, scale: {x: 1.75 + 0.008}, "piece_id": "Object071", object_name: 'blad_tussenstuk'},
];

export const tussenStuk3: Part[] = [
    {x: 269.2002 - 119.74805, y: 88 - 0, z: 92.69625 - 85.00003, box: {x: 5.423}, scale: {x: 59.874}, "piece_id": "Object071", object_name: 'blad_tussenstuk'},
    {x: 269.2002 - 119.74805, y: 88 - 72.45003, z: 0 - 0, box: {x: 5}, scale: {x: 59.874}, "piece_id": "Object023_011", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 72.45003, z: 0 - 28.00005, box: {x: 5}, scale: {x: 59.874}, "piece_id": "Object023_027", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 72.45003, z: 0 - 56.49999, box: {x: 5}, scale: {x: 59.874}, "piece_id": "Object023_020", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 72.45003, z: 0 - 85.00003, box: {x: 5}, scale: {x: 59.874}, "piece_id": "Object023_003", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 68.95004, z: 0 - 53.2 + 25.213 - 3.5, box: {x: 5}, scale: {x: 59.874}, "piece_id": "Object023_036", object_name: 'tussenstuk_plaat'},
    {x: 269.2002 - 119.74805, y: 88 - 3.2001, z: 0 - 0, box: {x: 5, y: 1.75}, scale: {x: 59.874, y: 3.5}, "piece_id": "Object023_015", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 3.2001, z: 0 - 28.00002, box: {x: 5, y: 1.75}, scale: {x: 59.874, y: 3.5}, "piece_id": "Object023_025", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 3.2001, z: 0 - 56.49999, box: {x: 5, y: 1.75}, scale: {x: 59.874, y: 3.5}, "piece_id": "Object023_023", object_name: 'tussenstuk_balk'},
    {x: 269.2002 - 119.74805, y: 88 - 3.2001, z: 0 - 85.00003, box: {x: 5, y: 1.75}, scale: {x: 59.874, y: 3.5}, "piece_id": "Object023_023", object_name: 'tussenstuk_balk'},
];

export const pootEind: Part[] = [
    {x: 269.2002, y: 0, z: 0, "piece_id": "", object_name: 'poot_eind'},
    {x: 269.2002 - 0.004, y: 88 - 0, z: 92.69625 - 85.00003, box: {x: 5.423}, scale: {x: 16 + 0.008}, "piece_id": "Object071", object_name: 'blad_tussenstuk'},
    {x: 269.2002 + 16 * 2, y: 88 - 0, z: 92.69625 - 85.00003, "piece_id": "poot_03", object_name: 'blad_eind'},
];

export const globalXOffset = (269.2002 + 16 * 2 + 5.353 * 2) / 2;
export const globalZOffset = (92.69625 / 2);

export function buildFrankie() {
    const {poot, opstelling, stukken} = models;
    const group = new THREE.Group();
    // console.log(tussenstuk);
    console.log(...opstelling.scene.children);

    const objects: { [name: string]: THREE.Mesh } = {};
    opstelling.scene.children.forEach(c => objects[c.name] = c as THREE.Mesh);

    // const pootBegin = (new THREE.Mesh()).copy(objects.poot_begin);
    // // pootBegin.position.set(269.2002, 88, 85.00003);
    // parts.push(pootBegin);
    // const pootEind = (new THREE.Mesh()).copy(objects.poot_eind);
    // pootEind.position.set(269.2002, 0, 0);
    // parts.push(pootEind);
    // const blad_begin = (new THREE.Mesh()).copy(objects.blad_begin);
    // blad_begin.position.set(269.2002 - 276.4043, 88 - 0, 92.69625 - 85.00003);
    // 33.898  ==> 39.632

    ([
        ...beginPoot,
        ...tussenStuk1,
        ...poot1,
        ...tussenStuk2,
        ...poot2,
        ...tussenStuk3,
        ...pootEind,
    ] as Part[]).forEach(item => {
        const object = objects[item.object_name];
        let meshes: THREE.Mesh[] = [];
        if (object.type === 'Mesh') meshes.push(object);
        else if (object.type === 'Group') meshes = object.children.filter(x => x.type === 'Mesh') as THREE.Mesh[];
        item.meshes = meshes.map(mesh => {
            const newMesh = (new THREE.Mesh()).copy(mesh);
            parts.push(newMesh);
            newMesh.position.set(item.x - globalXOffset, item.y, item.z + globalZOffset);
            if (item.scale) {
                const {scale, box} = item;
                (['x', 'y', 'z'] as ['x', 'y', 'z']).forEach((axis) => {
                    if (!scale[axis] === undefined || !box || box[axis] === undefined) return;
                    // @ts-ignore
                    const factor = scale[axis] / box[axis];
                    if (axis === 'x') newMesh.scale.setX(factor);
                    if (axis === 'y') newMesh.scale.setY(factor);
                    if (axis === 'z') newMesh.scale.setZ(factor);
                });
            }
            return newMesh;
        });
    });


    SceneReaderEngine.parseChildrenInModel(opstelling.scene);

    // parts = [tussenstuk.scene.children[0]];
    // const tussenStukModel = tussenstuk.scene.children[1];
    // tussenStukModel.scale.setY(0.01);
    // parts.push(tussenStukModel);
    // for (const key in tussenStukken) {
    //     const part = (new THREE.Mesh()).copy(tussenStukModel as THREE.Mesh);
    //     const {x, y, z} = tussenStukken[key];
    //     part.position.set(x, y, z);
    //     parts.push(part);
    // }
    // const otherPoot = (new THREE.Mesh()).copy(tussenstuk.scene.children[0] as THREE.Mesh);
    // otherPoot.position.setZ(1.015);
    // otherPoot.rotateY(0.5);
    //
    // parts.push(otherPoot);

    console.log(...parts);
    // parts[1].position.set(0.4325, 0.168, 0);
    group.add(...parts,); // ...tussenstuk.scene.children
    scene.add(group);
}

const modelsLoaded$ = loadModel(`models/frankies_onderdelen_base_position.glb`, 'opstelling');
// .then(_ => loadModel('models/Frankies_Opstelling_onderdelenOp0-0-0_Enmet_tussenstuk.gltf', 'stukken'))
// .then(_ => loadModel('models/Object_Poot01.gltf', 'poot'))
// .then(_ => loadModel('models/Object_Tussenstuk.gltf', 'tussenstuk'))
// loadTussenStuk(45, 25, 0);

const light = new THREE.AmbientLight(0x666666); // soft white light
scene.add(light);

scene.background = new THREE.Color(0xaaaaaa);

const controls = new OrbitControls(camera, renderer.domElement);
const m = 60;
camera.position.set(-6.8 * m, 3.8 * m, 5.7 * m);
controls.update();

let app: App;
const animate = function () {
    requestAnimationFrame(animate);
    // console.log(camera.position);

    // parts.forEach(p => scene.add(p.scene))

    // parts.forEach(p => {
    //     p.scene.rotation.x += 0.01;
    //     // p.scene.rotation.y += 0.01;
    // });
    // cube.rotation.x += 0.01;
    // cube.rotation.y += 0.01;

    controls.update();

    (app as any).setPosition(camera.position);
    renderer.render(scene, camera);
};

export async function initializeScene(initApp: App) {
    app = initApp;
    await modelsLoaded$;
    buildFrankie();
    animate();
}
