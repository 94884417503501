import * as THREE from 'three';
import {GLTF, GLTFLoader} from 'three/examples/jsm/loaders/GLTFLoader';
import {SceneEngine} from "@/engine/scene.engine";

export namespace SceneReaderEngine {
    export async function loadFile(location: string) {
        const loader = new GLTFLoader();
        await new Promise((res, rej) => {
            loader.load(location, function (gltf) {
                    res(gltf);
                }, undefined, function (error) {
                    console.error(error);
                    rej();
                }
            );
        });
    }

    export function parseChildrenInModel(mesh: THREE.Object3D) {
        const {children} = mesh;
        const pieces: any = {};
        const output: any = {
            pieces: [],
        };
        for (let i = 0; i < children.length; i++) {
            const child = children[i] as THREE.Mesh;
            const boundingBox = child?.geometry?.boundingBox;
            let minPosition = null;
            if (boundingBox) {
                const position = child.position;
                minPosition = {
                    x: position.x - boundingBox.min.x,
                    y: position.y - boundingBox.min.y,
                    z: position.z - boundingBox.min.z,
                };
            }
            pieces[child.name] = {piece_id: child.name, position: child.position, box: SceneEngine.getBoundingBoxSize(child) , calcPosition: minPosition};
            output.pieces.push(pieces[child.name]);
        }
        console.log(JSON.stringify(output, null, 4));
    }


}

if (!module.parent) {
    async function main() {
        // const path = require('path');
        // const baseSceneDir = '../../public/models/Frankies_Opstelling.glb';
        //
        // const baseScene = await SceneReaderEngine.loadFile(baseSceneDir);
        // const x = null;
    }

    main().catch(console.log);
}
